"use client";

//export initMocks async func instead
import initMocks from "@msw/index";
import ampli from "@services/skipifyEvents";
import fp from "@services/fingerprint";
import useMerchantConfigStore from "@stores/merchantConfigStore";
import themes from "@themes/index";
import { LDContextCommon, useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useParams, useSearchParams } from "next/navigation";
import { useEffect, useMemo, useState } from "react";
import useParentWindowComms from "./useParentWindowComms";
import getEnv from "@utils/getEnv";

type ExtraContext = {
  [contexts: string]: LDContextCommon;
};

const useCommonInitialization = (extraContext: ExtraContext = {}, defaultTheme = themes.default) => {
  const routeParams = useParams();
  const searchParams = useSearchParams();
  const client = useLDClient();

  const [appReady, setAppReady] = useState(false);
  const [amplitudeLoaded, setAmplitudeLoaded] = useState(false);
  const [launchDarklyLoaded, setLaunchDarklyLoaded] = useState(false);

  const safeDevMode = ["local", "dev", "test", "sandbox"].includes(getEnv().ENV || "");

  const theme = useMemo(() => {
    const themeOverrideParam = searchParams?.get("theme") || "";
    if (!safeDevMode || typeof themeOverrideParam !== "string" || !(themeOverrideParam in themes)) {
      return defaultTheme;
    }

    return themes[themeOverrideParam];
  }, [defaultTheme, safeDevMode, searchParams]);

  const [allowedDomains] = useMerchantConfigStore((state) => [state.allowedDomains]);

  const { sendLdFlags } = useParentWindowComms({
    allowedDomains,
  });

  const flags = useFlags();

  // Once all critical useEffects are run, flip appReady
  useEffect(() => {
    if (!launchDarklyLoaded || !amplitudeLoaded) {
      return;
    }

    setAppReady(true);
  }, [amplitudeLoaded, launchDarklyLoaded]);

  // Initializing the application
  // - mocks
  // - amplitude
  useEffect(() => {
    if (!launchDarklyLoaded) return;

    const init = async () => {
      if (getEnv().API_MOCKING === "enabled") {
        await initMocks();
      }

      // OTC to do - wrap in gcpa flag
      if (!ampli.isLoaded) {
        await ampli.enableServices(flags.useAmplitudeEvents, flags.useSkipifyEvents);
        await ampli.loadWithFingerprint();
        setAmplitudeLoaded(true);
      }
      ampli.refresh();
    };

    init();
  }, [flags, launchDarklyLoaded]);

  /**
   * This effect listens for changes in the device ID and the user and merges the changes into the launchdarkly context.
   * Listening at the _app(/pages) and GlobalContentWrapper (/app) level for now.
   */
  useEffect(() => {
    if (!client || launchDarklyLoaded) {
      return;
    }

    if (routeParams === null) {
      return;
    }

    const initLd = async () => {
      try {
        const deviceId = await fp.getFingerprint();
        const device = { key: deviceId, userAgent: window.navigator.userAgent };
        const merchant = routeParams.mid ? { key: routeParams.mid } : undefined;

        const ctx = {
          kind: "multi",
          user: { anonymous: true, key: deviceId },
          device,
          merchant,
          ...extraContext,
        };

        // Not catching errors for this right now
        await client.identify(ctx);
      } catch (e) {
        console.warn("[LaunchDarkly] error identifying the user with LaunchDarkly:", (e as Error).message);
      } finally {
        setLaunchDarklyLoaded(true);
      }
    };

    initLd();
  }, [amplitudeLoaded, client, extraContext, launchDarklyLoaded, routeParams, searchParams]);

  // Set the launchdarkly flags to the SDK and amplitude every time they change
  useEffect(() => {
    if (!launchDarklyLoaded) {
      return;
    }

    sendLdFlags({ ...flags });
    const flagValues = Object.entries(flags).reduce<string[]>((acc, [k, v]) => [...acc, `${k} : ${v}`], []);
    ampli.identify(undefined, { Experiments: flagValues });
  }, [flags, launchDarklyLoaded, sendLdFlags]);

  return {
    appReady,
    theme,
    safeDevMode,
  };
};

export default useCommonInitialization;
